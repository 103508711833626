.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-track {
  display: flex;
  transition: transform 0.3s ease;
  cursor: grab;
}

.carousel-item {
  display: flex;
  min-width: 700px;
  height: 900px;
  background-color: #000;
  color: #888;
  justify-content: center;
  padding: 20px;
  text-align: center;
  user-select: none;
}
.carousel-item:hover {
  cursor: url("/public/cursor.png"), pointer;
}
.carousel-column {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.carousel-column p {
  font-size: 128px;
  font-weight: 700;
  line-height: 156.03px;
  margin: 0;
}
.carousel-column p:hover {
  cursor: url("/public/cursor.png"), pointer;
}
.carousel-track:active {
  cursor: grabbing;
}


@media (max-width: 1400px) {
  .carousel-item {
    min-width: 600px;
    height: 800px;
  }
}
@media (max-width: 1200px) {
  .carousel-item {
    min-width: 500px;
    height: 700px;
  }
  .carousel-column p {
    font-size: 128px;
    font-weight: 700;
    line-height: 156.03px;
    margin: 0;
  }
}
@media (max-width: 992px) {
  .carousel-item {
    min-width: 400px;
    height: 600px;
  }
  .carousel-column p {
    font-size: 128px;
    font-weight: 700;
    line-height: 156.03px;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .carousel-item {
    min-width: 400px;
    height: 600px;
    
  }
  .carousel-column p {
    font-size: 58px;
    font-weight: 700;
    line-height: 156.03px;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .carousel-track {
    display: flex;
    transition: transform 0.3s ease;
    cursor: grab;
  }

  .carousel-item:hover {
    cursor: url("/public/cursor.png"), pointer;
  }
  .carousel-column {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }
  .carousel-item {
    min-width: 300px;
    height: 300px;
  }
  .carousel-column p {
    font-size: 26px;
    font-weight: 700;
    line-height: 31.69px;
    margin: 0;
  }
  
}
