* {
  cursor: url("/public/cursordef.svg"), pointer;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.logoo {
  position: fixed;
  animation: spin 0.5s linear;
  font-weight: 700;
  font-size: 164px;
}

.loaded {
  display: none;
}

@keyframes spin {
  0% {
    top: 50%;
    left: 50%;
    font-size: 13px;
    display: block;
  }
  100% {
    font-size: 164px;
    display: none;
    top: 127px;
    left: 630px;
  }
}

@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SFProText800.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
button:hover {
  cursor: url("/public/cursor.png"), pointer;
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
  color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.logo {
  font-size: 164px;
  font-weight: 700;
  line-height: 199.92px;
  text-align: center;
  margin: 6px;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.logo::before {
  content: "";
  position: absolute;
  left: -10px;
  top: -100px;
  background-image: url("/public/paper1.png");
  width: 626px;
  height: 938px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.next-event {
  font-weight: 600;
  font-size: 54px;
  line-height: 65.83px;
  text-align: center;
  margin: 0px;
  margin-bottom: 60px;
}
.home-container,
.event-details-container,
.merch-container {
  padding: 20px;
}
.event-section {
  background-color: #1d1d1d;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.event-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: auto;
  margin-bottom: 160px;
  position: relative;
  flex-direction: row-reverse;
}
.event-card img {
  max-height: 831px;
  max-width: 623px;
  filter: blur(26px);
  z-index: 0;
}

.event-card::before {
  content: "";
  height: 776px;
  width: 583px;
  background-image: url("../public/123123.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: 1;
  right: 0px;
}
.event-card strong {
  font-weight: 700;
  font-size: 43px;
}
.event-card p {
  font-weight: 400;
  font-size: 43px;
  line-height: 52.42px;
}
.event-section img {
  height: auto;
  border-radius: 10px;
  width: 623px;
  height: 831px;
}
.buy-ticket-btn {
  width: 608.14px;
  height: 129px;
  border-radius: 30px;
  background-color: #1a1a1a;
  font-size: 600;
  font-size: 41px;
  line-height: 49.98px;
  align-items: Center;
  color: #fff;
  border: 0;
  margin-top: 140px;
}
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.image-gallery img {
  max-width: calc(33.333% - 10px); /* 3 изображения в ряд */
  margin: 10px 0;
  transition: transform 0.3s;
}

.image-gallery img:hover {
  transform: scale(1.05);
}
.merch-logo {
  font-weight: 600;
  font-size: 54px;
  line-height: 125.3px;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.merch-logo::before {
  content: "Мерч";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/paper2.png");
  width: 100%;
  height: 252px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.make-logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 300px;
  margin-top: 300px;
}

.make-logo-background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/paper3.png");
  width: 100%;
  height: 500px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}

.make-logo {
  font-weight: 800;
  font-size: 105px;
  line-height: 65.83px;
  text-align: center;
  position: relative;
  z-index: 1;
  font-family: "SF Pro Text", sans-serif;
  filter: blur(6px);
  margin-bottom: 100px;
  box-shadow: 0px 38px 38px rgba(255, 255, 255, 0.51),
    0px 86px 51px rgba(255, 255, 255, 0.3),
    0px 153px 61px rgba(255, 255, 255, 0.09),
    0px 238px 67px rgba(255, 255, 255, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.footer-title {
  font-weight: 600;
  font-size: 54px;
  line-height: 65.83px;
}
.footer-socials p {
  font-weight: 400;
  font-size: 43px;
  line-height: 52.42px;
  max-width: 1400px;
  margin: auto;
}
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.footer-navbar ul {
  list-style: none;
  display: flex;
  gap: 12px;
  width: 250px;
  margin: auto;
  margin-top: 100px;
  justify-content: space-around;
}
.footer-navbar {
  position: relative;
}
.footer-navbar::before {
  content: "";
  position: absolute;
  right: -40px;
  bottom: -40px;
  background-image: url("/public/paper4.png");
  width: 626px;
  height: 938px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.footer-navbar ul li {
  width: 60px;
  height: 80px;
}
.inst {
  width: 60px;
  height: 80px;
  background-image: url("../public/inst.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.vk {
  width: 60px;
  height: 80px;
  background-image: url("../public/vk.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.tg {
  width: 60px;
  height: 80px;
  background-image: url("../public/tg.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.event-info {
  margin-left: 20px;
}

.footer-socials {
  text-align: center;
  padding: 20px;
}

.event-details-container img {
  width: 100%;
  height: auto;
}

#loading-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1440px) {
  .logo::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 00px;
    background-image: url("/public/paper1.png");
    width: 327px;
    height: 465px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .footer-navbar::before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: -150%;
    background-image: url("/public/paper4.png");
    width: 306px;
    height: 308px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@media (max-width: 1024px) {
  .logo {
    font-size: 65px;
    font-weight: 700;
    line-height: 70.17px;
    text-align: center;
    margin: 6px;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .logo::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -100px;
    background-image: url("/public/paper1.png");
    width: 347px;
    height: 485px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .next-event {
    font-weight: 600;
    font-size: 36px;
    line-height: 31.69px;
    text-align: center;
    margin: 0px;
    margin-bottom: 60px;
  }
  .home-container,
  .event-details-container,
  .merch-container {
    padding: 20px;
  }
  .event-section {
    background-color: #1d1d1d;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }

  .event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 100%;
    position: relative;
    margin: 0px auto 150px auto;
  }
  .event-card img {
    height: 471px;
    width: 333px;
    filter: blur(26px);
    z-index: 0;
    margin: 0;
  }

  .event-card::before {
    content: "";
    height: 526px;
    width: 333px;
    background-image: url("../public/123123.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .event-card strong {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 10px;
  }
  .event-card p {
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 30.41px;
  }
  .event-section img {
    height: auto;
    border-radius: 10px;
    width: 800px;
    height: 600px;
  }
  .event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .buy-ticket-btn {
    width: 350px;
    height: 70px;
    border-radius: 15px;
    background-color: #1a1a1a;
    font-size: 600;
    font-size: 28px;
    line-height: 17.07px;
    align-items: Center;
    color: #fff;
    border: 0;
    margin-top: 10px;
  }

  .merch-logo {
    font-weight: 600;
    font-size: 26px;
    line-height: 17.07px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .merch-logo::before {
    content: "Мерч";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/paper2.png");
    width: 100%;
    height: 184px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }
  .make-logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
    margin-top: 250px;
  }

  .make-logo-background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/paper3.png");
    width: 100%;
    height: 208px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }

  .make-logo {
    font-weight: 800;
    font-size: 47px;
    line-height: 35.83px;
    text-align: center;
    position: relative;
    z-index: 1;
    font-family: "SF Pro Text", sans-serif;
    filter: blur(5px);
    margin-bottom: 100px;
    box-shadow: 0px 38px 38px rgba(255, 255, 255, 0.51),
      0px 46px 31px rgba(255, 255, 255, 0.3),
      0px 83px 41px rgba(255, 255, 255, 0.09),
      0px 138px 37px rgba(255, 255, 255, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .footer-title {
    font-weight: 600;
    font-size: 46px;
    line-height: 88.83px;
  }
  .footer-socials p {
    font-weight: 400;
    font-size: 26px;
    line-height: 29.07px;
    max-width: 1400px;
    margin: auto;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .footer-navbar ul {
    list-style: none;
    display: flex;
    gap: 12px;
    width: 250px;
    margin: auto;
    margin-top: 100px;
  }
  .footer-navbar {
    position: relative;
  }
  .footer-navbar::before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: -150%;
    background-image: url("/public/paper4.png");
    width: 276px;
    height: 238px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .footer-navbar ul li {
    width: 60px;
    height: 80px;
  }
  .inst {
    width: 60px;
    height: 80px;
    background-image: url("../public/inst.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .vk {
    width: 60px;
    height: 80px;
    background-image: url("../public/vk.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .tg {
    width: 60px;
    height: 80px;
    background-image: url("../public/tg.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .event-info {
    margin-left: 20px;
  }

  .footer-socials {
    text-align: center;
    padding: 20px;
  }

  .event-details-container img {
    width: 100%;
    height: auto;
  }

  #loading-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .logo {
    font-size: 51px;
    font-weight: 700;
    line-height: 62.17px;
    text-align: center;
    margin: 6px;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .logo::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -100px;
    background-image: url("/public/paper1.png");
    width: 237px;
    height: 355px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .next-event {
    font-weight: 600;
    font-size: 26px;
    line-height: 31.69px;
    text-align: center;
    margin: 0px;
    margin-bottom: 60px;
  }
  .home-container,
  .event-details-container,
  .merch-container {
    padding: 20px;
  }
  .event-section {
    background-color: #1d1d1d;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }

  .event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 100%;
    position: relative;
    margin: 0px auto 50px auto;
  }
  .event-card img {
    max-height: 253px;
    max-width: 190px;
    filter: blur(26px);
    z-index: 0;
    margin: 0;
  }

  .event-card::before {
    content: "";
    height: 235px;
    width: 178px;
    background-image: url("../public/123123.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .event-card strong {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .event-card p {
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    line-height: 13.41px;
  }
  .event-section img {
    height: auto;
    border-radius: 10px;
    width: 800px;
    height: 600px;
  }
  .event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .buy-ticket-btn {
    width: 190px;
    height: 40px;
    border-radius: 15px;
    background-color: #1a1a1a;
    font-size: 600;
    font-size: 14px;
    line-height: 17.07px;
    align-items: Center;
    color: #fff;
    border: 0;
    margin-top: 10px;
  }

  .merch-logo {
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .merch-logo::before {
    content: "Мерч";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/paper2.png");
    width: 100%;
    height: 74px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }
  .make-logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .make-logo-background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/paper3.png");
    width: 100%;
    height: 108px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }

  .make-logo {
    font-weight: 800;
    font-size: 26px;
    line-height: 31.03px;
    text-align: center;
    position: relative;
    z-index: 1;
    font-family: "SF Pro Text", sans-serif;
    filter: blur(3px);
    margin-bottom: 100px;
    box-shadow: 0px 38px 38px rgba(255, 255, 255, 0.51),
      0px 46px 31px rgba(255, 255, 255, 0.3),
      0px 83px 41px rgba(255, 255, 255, 0.09),
      0px 138px 37px rgba(255, 255, 255, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .footer-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 65.83px;
  }
  .footer-socials p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17.07px;
    max-width: 1400px;
    margin: auto;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .footer-navbar ul {
    list-style: none;
    display: flex;
    gap: 12px;
    width: 250px;
    margin: auto;
    margin-top: 100px;
    justify-content: space-around;
  }
  .footer-navbar {
    position: relative;
  }
  .footer-navbar::before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: -150%;
    background-image: url("/public/paper4.png");
    width: 276px;
    height: 238px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 0;
  }
  .footer-navbar ul li {
    width: 40px;
    height: 60px;
  }
  .inst {
    width: 40px;
    height: 60px;
    background-image: url("../public/inst.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .vk {
    width: 40px;
    height: 60px;
    background-image: url("../public/vk.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .tg {
    width: 40px;
    height: 60px;
    background-image: url("../public/tg.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .event-info {
    margin-left: 20px;
  }

  .footer-socials {
    text-align: center;
    padding: 20px;
  }

  .event-details-container img {
    width: 100%;
    height: auto;
  }

  #loading-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 400px) {
  .logo {
    font-size: 51px;
    font-weight: 700;
    line-height: 62.17px;
    text-align: center;
    margin: 6px;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .logo::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -100px;
    background-image: url("/public/paper1.png");
    width: 23 7px;
    height: 355px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .next-event {
    font-weight: 600;
    font-size: 26px;
    line-height: 31.69px;
    text-align: center;
    margin: 0px;
    margin-bottom: 60px;
  }
  .home-container,
  .event-details-container,
  .merch-container {
    padding: 20px;
  }
  .event-section {
    background-color: #1d1d1d;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }

  .event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 100%;
    position: relative;
    margin: 0px auto 50px auto;
  }
  .event-card img {
    max-height: 203px;
    max-width: 150px;
    filter: blur(26px);
    z-index: 0;
    margin: 0;
  }

  .event-card::before {
    content: "";
    height: 195px;
    width: 138px;
    background-image: url("../public/123123.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .event-card strong {
    font-weight: 700;
    font-size: 11px;
    margin-bottom: 10px;
  }
  .event-card p {
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 9px;
    line-height: 13.41px;
  }
  .event-section img {
    height: auto;
    border-radius: 10px;
    width: 800px;
    height: 600px;
  }
  .event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .buy-ticket-btn {
    width: 150px;
    height: 35px;
    border-radius: 15px;
    background-color: #1a1a1a;
    font-size: 600;
    font-size: 13px;
    line-height: 17.07px;
    align-items: Center;
    color: #fff;
    border: 0;
    margin-top: 10px;
  }

  .merch-logo {
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .merch-logo::before {
    content: "Мерч";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/paper2.png");
    width: 100%;
    height: 74px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }
  .make-logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 100px;
  }
}
